<template>
    <v-card flat>
        <v-card-title>
            <span class="secondary--text font-weight-bold">Applications</span>
        </v-card-title>
        <v-card-text>
            <v-skeleton-loader v-if="applicationsFetching && fetchingNodePools" class="mx-auto" type="table"></v-skeleton-loader>
            <template v-else>
                <div class="d-flex align-center">
                    <v-btn
                        v-if="(isDevelopment && isSpaceAdmin) || (isDevelopment && currentSpaceType !== spaceTypes.EDUCATION_SPACE && isInstanceEditor)"
                        :to="{
                            name: 'snapshot-applications-add',
                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                        }"
                        color="primary"
                        :disabled="applicationsFetching || isSpaceArchived"
                        class="mr-2">
                        <v-icon left>add</v-icon>
                        add new application
                    </v-btn>
                    <v-dialog v-else v-model="AddAppdialog" max-width="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="primary" class="mr-2">
                                <v-icon left>add</v-icon>
                                add new application
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline secondary--text">Insufficient privileges</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="mt-1">
                                <p>
                                    New applications can be added only by course admins. If you require an additional application for your work, please ask a
                                    course admin to add the application. As a reference, they can do it at the current URL:
                                </p>
                                <div class="d-flex align-center justify-space-between mt-2">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <span class="secondary--text text-truncate" v-on="on">{{ currentURL.substring(0, 100) }}...</span>
                                        </template>
                                        <span>{{ currentURL }}</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <copy-to-clipboard
                                                    :textToCopy="currentURL"
                                                    buttonColor="secondary"
                                                    :iconButton="true"
                                                    :isSmall="true"></copy-to-clipboard>
                                            </div>
                                        </template>
                                        <span>Copy to clipboard</span>
                                    </v-tooltip>
                                </div>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="AddAppdialog = false">close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-btn v-if="isInstanceEditor" :to="{ name: 'snapshot-images' }" class="mr-2">
                        <v-icon left>mdi-export</v-icon>
                        Exported Applications
                    </v-btn>
                    <v-divider vertical class="mr-3 ml-1"></v-divider>
                    <v-btn v-if="!isTrialSpace && applicationsWithStatus.length && selected.length > 0" @click="addToStaging(selected)" class="mr-2">
                        <v-icon left>share</v-icon>
                        stage selected ({{ selected.length }})
                    </v-btn>
                    <v-menu offset-y bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="secondary" icon dark class="caption mr-2">
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </template>
                        <v-list nav dense class="py-4">
                            <v-list-item>
                                <v-btn
                                    @click="addToStaging(applicationsWithStatus)"
                                    :disabled="isTrialSpace || applicationsWithStatus.length === 0"
                                    block
                                    text
                                    color="secondary"
                                    class="justify-start">
                                    <v-icon left>share</v-icon>
                                    stage all ({{ applicationsWithStatus.length }})
                                </v-btn>
                            </v-list-item>
                            <v-list-item v-if="!isTrialSpace && applicationsWithStatus.length">
                                <v-btn :disabled="selected.length === 0" @click="addToStaging(selected)" block text color="secondary" class="justify-start">
                                    <v-icon left>share</v-icon>
                                    stage selected ({{ selected.length }})
                                </v-btn>
                            </v-list-item>
                            <v-divider v-if="isSpaceAdmin || isInstanceEditor" class="my-3"></v-divider>
                            <template v-if="isSpaceAdmin || isInstanceEditor">
                                <v-list-item v-if="isDevelopment" e2e-app-delete-for-me>
                                    <app-delete-dialog :selected="selected" :disabled="selected.length === 0" @finished="selected = []">
                                        <v-btn text block color="secondary" class="justify-start" :disabled="selected.length === 0">
                                            <v-icon left>delete</v-icon>
                                            {{ getDeleteLabel }}
                                        </v-btn>
                                    </app-delete-dialog>
                                </v-list-item>
                                <template v-if="currentSpaceType !== spaceTypes.RESEARCH_SPACE && isSpaceAdmin">
                                    <v-list-item e2e-app-delete-for-others v-if="isMasterInstance || isDistributedInstance">
                                        <app-delete-dialog :selected="selected" :mode="2" :disabled="selected.length === 0" @finished="selected = []">
                                            <v-btn text block color="secondary" class="justify-start" :disabled="selected.length === 0">
                                                <v-icon left>delete</v-icon>
                                                {{ getDeleteForOthersLabel }}
                                            </v-btn>
                                        </app-delete-dialog>
                                    </v-list-item>
                                    <v-list-item v-if="isDevelopment && isMasterInstance" e2e-app-delete-for-all>
                                        <app-delete-dialog :selected="selected" :mode="1" :disabled="selected.length === 0" @finished="selected = []">
                                            <v-btn text block color="secondary" class="justify-start" :disabled="selected.length === 0">
                                                <v-icon left>delete</v-icon>
                                                Delete for all
                                            </v-btn>
                                        </app-delete-dialog>
                                    </v-list-item>
                                </template>
                            </template>
                        </v-list>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-text-field
                        prepend-inner-icon="mdi-filter"
                        autofocus
                        hide-details
                        solo
                        flat
                        background-color="grey lighten-4"
                        dense
                        label="Search for an application..."
                        v-model="search"
                        clearable
                        class="mr-2"
                        @click:clear="removeSearchQuery"></v-text-field>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="$store.dispatch('snapshotStore/fetchApplications', $route.params.snid)"
                                icon
                                v-on="on"
                                :loading="applicationsFetching">
                                <v-icon>refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh Applications</span>
                    </v-tooltip>
                </div>
                <v-data-table
                    :search="search"
                    show-select
                    item-key="aid"
                    :headers="headersFiltered"
                    v-model="selected"
                    :options="tableOptions"
                    :items="applicationsWithStatus"
                    :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
                    class="shepherd-app-staging-step-1">
                    <template v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :itemsPerPageOptions="itemsPerPageOptions"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                            class="no-border" />
                    </template>
                    <template v-slot:no-data>
                        <span>No applications found. Consider adding a new application via the "Add New Application" button above.</span>
                    </template>
                    <template v-slot:[`item.icon`]="{ item }">
                        <div class="d-flex">
                            <img width="30" height="30" :src="appIcon(item)" />
                        </div>
                    </template>
                    <template v-slot:[`item.long_id`]="{ item }">
                        <v-btn v-if="isDevelopment && !isInstanceArchived" text @click="openApp(item.aid)" class="text-none">
                            {{ item.long_id }}
                            <v-icon right color="primary" class="showOnHover">mdi-play-circle-outline</v-icon>
                        </v-btn>
                        <div v-else>
                            <span>{{ item.long_id }}</span>
                            <v-tooltip bottom v-if="isInstanceArchived">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="ml-1"
                                        small
                                        icon
                                        :to="{
                                            name: 'instance-snapshots',
                                            params: {
                                                oid: $route.params.oid,
                                                sid: $route.params.sid,
                                                iid: $route.params.iid,
                                                snid: $route.params.snid
                                            }
                                        }">
                                        <v-icon v-on="on">info</v-icon>
                                    </v-btn>
                                </template>
                                <span>This instance is archived, please restore a snapshot first</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:[`item.size`]="{ item }">
                        <app-scale-info-menu :app="item">
                            <v-btn
                                text
                                :to="
                                    currentSpaceType === spaceTypes.EDUCATION_SPACE && !isSpaceAdmin
                                        ? ''
                                        : {
                                              name: 'application-scale',
                                              params: { aid: item.aid }
                                          }
                                "
                                class="text-none secondary--text">
                                <template v-if="item.active_resource === 'ncu'">
                                    <v-icon left color="primary">mdi-card-bulleted-outline</v-icon>
                                    {{ `${item.ncu} CU` }}
                                </template>
                                <template v-else>
                                    <v-icon left color="purple" v-if="getNodepoolFamily(currentNodePool(item.node_pool)) === 'cpu'">mdi-memory</v-icon>
                                    <v-icon left color="light-green" v-if="getNodepoolFamily(currentNodePool(item.node_pool)) === 'gpu'">
                                        mdi-memory mdi-rotate-45
                                    </v-icon>
                                    <v-icon left color="orange" v-if="getNodepoolFamily(currentNodePool(item.node_pool)) === 'ssd'">mdi-nas</v-icon>
                                    {{ `${getNodepoolPrimaryParameter(currentNodePool(item.node_pool))}` }}
                                </template>
                                <v-icon v-if="!(currentSpaceType === spaceTypes.EDUCATION_SPACE && !isSpaceAdmin)" right class="showOnHover">settings</v-icon>
                            </v-btn>
                        </app-scale-info-menu>
                    </template>
                    <template v-slot:[`item.active_resource`]="{ item }">
                        {{ item.active_resource === 'ncu' ? '-' : `${currentNodePool(item.node_pool).credits_per_hour.toFixed(2)}` }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <v-btn v-if="item.status === 'Stopped'" @click="openApp(item.aid)" fab x-small dark color="primary">
                            <v-icon small>power_settings_new</v-icon>
                        </v-btn>
                        <stop-application
                            v-if="item.status === 'Starting' || item.status === 'Stopping'"
                            :appName="item.long_id"
                            :appId="item.aid.toString()"
                            :isShared="item.shared === 'true'">
                            <v-btn fab x-small dark color="deep-orange lighten-2">
                                <v-progress-circular size="18" color="white" indeterminate />
                            </v-btn>
                        </stop-application>
                        <v-btn v-if="item.status === 'Deleting'" fab x-small dark color="blue-grey lighten-1">
                            <v-icon small>delete</v-icon>
                        </v-btn>
                        <stop-application
                            v-if="item.status === 'Running'"
                            :appName="item.long_id"
                            :appId="item.aid.toString()"
                            :isShared="item.shared === 'true'">
                            <v-btn fab x-small dark color="success">
                                <v-icon small>power_settings_new</v-icon>
                            </v-btn>
                        </stop-application>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <version-description-viewer
                            :name="item.long_id"
                            :iconUrl="item.icon_url"
                            :descriptionUrl="item.image_description"
                            v-if="item.image_description">
                            <a class="subtitle-2">{{ item.description }}</a>
                        </version-description-viewer>
                        <span v-else class="subtitle-2 secondary--text">{{ item.description }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex flex-nowrap justify-end">
                            <v-btn v-if="!isTrialSpace" @click="addToStaging([item])" color="secondary" class="mr-2 showOnHover" small outlined text>
                                <v-icon left>share</v-icon>
                                stage
                            </v-btn>
                            <v-menu v-model="dropdownMenu[`app_${item.aid}`]" left offset-x>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        text
                                        color="secondary"
                                        small
                                        class="prestart_tour_1 mr-3"
                                        :disabled="isDistributedInstance && !isSpaceAdmin">
                                        <v-icon>more_horiz</v-icon>
                                    </v-btn>
                                </template>
                                <v-list nav dense class="py-4">
                                    <v-list-item>
                                        <application-config
                                            :maxNCU="currentSpaceType !== spaceTypes.EDUCATION_SPACE ? 16 : 4"
                                            :aid="item.aid"
                                            :canSave="isSpaceAdmin || (isInstanceEditor && currentSpaceType === spaceTypes.RESEARCH_SPACE)"
                                            :isTrial="isTrialSpace">
                                            <v-btn @click="closeMenu(item)" text block color="secondary" class="justify-start">
                                                <v-icon left>settings</v-icon>
                                                Configure
                                            </v-btn>
                                        </application-config>
                                    </v-list-item>
                                    <template v-if="isDevelopment">
                                        <v-list-item>
                                            <v-btn
                                                block
                                                text
                                                color="secondary"
                                                :to="{ query: { resetAid: item.aid } }"
                                                class="justify-start"
                                                e2e-app-clear-settings>
                                                <v-icon left>auto_fix_high</v-icon>
                                                Clear Settings
                                            </v-btn>
                                        </v-list-item>
                                        <v-list-item v-if="isSpaceAdmin">
                                            <app-rename-dialog :current="item">
                                                <v-btn @click="closeMenu(item)" text block color="secondary" class="justify-start" e2e-app-rename>
                                                    <v-icon left>edit</v-icon>
                                                    Rename application
                                                </v-btn>
                                            </app-rename-dialog>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="
                                                (spaceTypes.RESEARCH_SPACE && (isSpaceAdmin || isInstanceEditor)) ||
                                                (!spaceTypes.RESEARCH_SPACE && isSpaceAdmin)
                                            ">
                                            <app-clone-btn :application="item">
                                                <v-btn @click="closeMenu(item)" text block color="secondary" class="justify-start">
                                                    <v-icon left>animation</v-icon>
                                                    Clone
                                                </v-btn>
                                            </app-clone-btn>
                                        </v-list-item>
                                        <v-list-item v-if="item.status !== 'Stopped'">
                                            <stop-application :appName="item.long_id" :appId="item.aid.toString()" :isShared="item.shared === 'true'">
                                                <v-btn block text color="secondary" e2e-app-stop class="justify-start">
                                                    <v-icon left>mdi-square</v-icon>
                                                    Stop
                                                </v-btn>
                                            </stop-application>
                                        </v-list-item>
                                        <v-list-item v-if="isSpaceAdmin && isMasterInstance">
                                            <v-btn
                                                :loading="startingAppForAll"
                                                text
                                                block
                                                color="secondary"
                                                class="justify-start"
                                                @click="startAppForAll(item.aoid)"
                                                e2e-app-start-for-all>
                                                <v-icon left>launch</v-icon>
                                                Start for all users
                                            </v-btn>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="isSpaceAdmin && isMasterInstance && currentSpaceType === spaceTypes.EDUCATION_SPACE && !isSpaceArchived">
                                            <app-schedule-dialog :schedules="schedules" :current="item" @fetchSchedules="fetchSchedules">
                                                <v-btn @click="closeMenu(item)" text block color="secondary" class="justify-start prestart_tour_2">
                                                    <v-icon left>schedule_send</v-icon>
                                                    Schedule for start
                                                </v-btn>
                                            </app-schedule-dialog>
                                        </v-list-item>
                                        <v-divider v-if="isInstanceEditor && !isSpaceArchived && !isTrialSpace && item.exportable" class="my-3" />
                                        <v-list-item v-if="isInstanceEditor && !isSpaceArchived && item.exportable">
                                            <v-btn text color="secondary" block class="justify-start" @click="showDockerTagDialogForApp(item)">
                                                <v-icon left>mdi-export</v-icon>
                                                Export application
                                            </v-btn>
                                        </v-list-item>
                                        <v-divider v-if="isSpaceAdmin || isInstanceEditor" class="my-3"></v-divider>
                                    </template>
                                    <template v-if="isSpaceAdmin || isInstanceEditor">
                                        <v-list-item v-if="isDevelopment" e2e-app-delete-for-me>
                                            <app-delete-dialog :selected="[item]" :disabled="item.status === 'Running'">
                                                <v-btn
                                                    @click="closeMenu(item)"
                                                    text
                                                    block
                                                    color="secondary"
                                                    class="justify-start"
                                                    :disabled="item.status === 'Running'">
                                                    <v-icon left>delete</v-icon>
                                                    {{ getDeleteLabel }}
                                                </v-btn>
                                            </app-delete-dialog>
                                        </v-list-item>
                                        <template v-if="currentSpaceType !== spaceTypes.RESEARCH_SPACE && isSpaceAdmin">
                                            <v-list-item e2e-app-delete-for-others v-if="isMasterInstance || isDistributedInstance">
                                                <app-delete-dialog :selected="[item]" :mode="2" :disabled="item.status === 'Running'">
                                                    <v-btn
                                                        @click="closeMenu(item)"
                                                        text
                                                        block
                                                        color="secondary"
                                                        class="justify-start"
                                                        :disabled="item.status === 'Running'">
                                                        <v-icon left>delete</v-icon>
                                                        {{ getDeleteForOthersLabel }}
                                                    </v-btn>
                                                </app-delete-dialog>
                                            </v-list-item>
                                            <v-list-item v-if="isDevelopment && isMasterInstance" e2e-app-delete-for-all>
                                                <app-delete-dialog :selected="[item]" :mode="1" :disabled="item.status === 'Running'">
                                                    <v-btn
                                                        @click="closeMenu(item)"
                                                        text
                                                        block
                                                        color="secondary"
                                                        class="justify-start"
                                                        :disabled="item.status === 'Running'">
                                                        <v-icon left>delete</v-icon>
                                                        Delete for all
                                                    </v-btn>
                                                </app-delete-dialog>
                                            </v-list-item>
                                        </template>
                                    </template>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                    <template v-slot:[`item.lib_usage`]="{ item }">
                        {{ item.lib_usage === null ? '' : humanFileSize(item.lib_usage) }}
                    </template>
                </v-data-table>
            </template>
        </v-card-text>
        <template v-if="isSpaceAdmin && currentSpaceType === spaceTypes.EDUCATION_SPACE && isMasterInstance">
            <v-card-subtitle>Schedules</v-card-subtitle>
            <v-card-text>
                <template>
                    <v-data-table
                        item-key="aid"
                        :headers="scheduleHeaders"
                        :items="filteredSchedules"
                        :loading="loading"
                        :options="tableOptions"
                        :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
                        class="pb-12">
                        <template v-slot:top="{ pagination, options, updateOptions }">
                            <div class="d-flex align-center">
                                <v-checkbox
                                    v-model="showOldSchedules"
                                    hide-details
                                    label="Show past schedules too"
                                    :disabled="loading"
                                    class="ma-0 pa-0"></v-checkbox>
                                <v-spacer></v-spacer>
                                <v-data-footer
                                    :pagination="pagination"
                                    :options="options"
                                    @update:options="updateOptions"
                                    :itemsPerPageOptions="itemsPerPageOptions"
                                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                                    class="no-border" />
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <span>No scheduled applications found. Consider scheduling a new application via the "Schedule for start" button in actions.</span>
                        </template>
                        <template v-slot:[`item.icon`]="{ item }">
                            <div class="d-flex">
                                <img width="30" height="30" :src="appIcon(item)" />
                            </div>
                        </template>
                        <template v-slot:[`item.long_id`]="{ item }">
                            <div v-if="isDevelopment && !isInstanceArchived" class="subtitle-2">
                                {{ item.long_id }}
                            </div>
                            <div v-else>
                                <span>{{ item.long_id }}</span>
                                <v-tooltip bottom v-if="isInstanceArchived">
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            class="ml-1"
                                            small
                                            icon
                                            :to="{
                                                name: 'instance-snapshots',
                                                params: {
                                                    oid: $route.params.oid,
                                                    sid: $route.params.sid,
                                                    iid: $route.params.iid,
                                                    snid: $route.params.snid
                                                }
                                            }">
                                            <v-icon v-on="on">info</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>This instance is archived, please restore a snapshot first</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:[`item.startup_time`]="{ item }">
                            {{ item.startup_time | dateTimeToHuman }}
                        </template>
                        <template v-slot:[`item.type_value`]="{ item }">
                            <v-chip small v-if="item.node_pool">{{ item.type_value }}</v-chip>
                        </template>
                        <template v-slot:[`item.stop_after_minutes`]="{ item }">
                            <div v-if="item.node_pool">{{ item.stop_after_minutes }}</div>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="d-flex flex-nowrap justify-end">
                                <v-btn
                                    :disabled="isSpaceArchived || disableScheduleNextWeek(item) || loading"
                                    outlined
                                    small
                                    @click="setScheduleNextWeek(item.aoid, item.startup_time, item?.node_pool, item?.stop_after_minutes)"
                                    color="primary"
                                    text
                                    class="showOnHover mr-1">
                                    <v-icon left small>navigate_next</v-icon>
                                    Add to next week
                                </v-btn>
                                <app-schedule-dialog
                                    v-if="!item.past && !loading"
                                    :schedules="schedules"
                                    :current="item"
                                    crud="update"
                                    @fetchSchedules="fetchSchedules">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon small color="secondary" class="mr-1" v-bind="attrs" v-on="on">
                                                <v-icon small>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Edit</span>
                                    </v-tooltip>
                                </app-schedule-dialog>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon small color="secondary" :disabled="loading" @click="deleteSchedule(item.ssid)" v-bind="attrs" v-on="on">
                                            <v-icon small>delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </v-data-table>
                </template>
            </v-card-text>
        </template>
        <ResetApplicationDialog />
        <v-dialog v-model="dockerExportTagDialog" max-width="600px" lazy-validation>
            <v-card>
                <v-card-title>Choose an optional tag for your app</v-card-title>
                <v-card-subtitle class="caption">A random tag will be generated if none given.</v-card-subtitle>
                <v-card-text class="mb-0">
                    <v-form v-model="dockerExportTagIsValid">
                        <v-text-field
                            v-model="dockerExportTag"
                            outlined
                            hint="Lower case letters, numbers, dashes and underscores only"
                            :rules="[rules.validChars]"
                            dense>
                            <template v-slot:label>Type a tag here</template>
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-text v-if="isRunning">
                    <v-alert prominent outlined type="warning" class="my-0">
                        We recommend stopping your application before exporting to prevent possible inconsitencies.
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-checkbox v-model="skipFileExport">
                        <template v-slot:label>
                            <span class="caption">Do not export workspace files</span>
                        </template>
                    </v-checkbox>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dockerExportTagDialog = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="exportToImage()" :disabled="!dockerExportTagIsValid">Export</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { enumsData } from '@/mixins/enums'
import tour from '@/mixins/tour'
import { humanFileSize, dateTimeConcat } from '@/utils'
import { fetchAppStartupSchedules, setAppStartupSchedule, deleteAppStartupSchedule } from '@/apis'
import { DateTime } from 'luxon'
import Shepherd from 'shepherd.js'
import { scaling } from '@/mixins/scaling'

const CopyToClipboard = () => import('@/components/CopyToClipboard')
const StopApplication = () => import('@/modules/application/components/TheApplicationStopDialog')
const ApplicationConfig = () => import('@/modules/application/components/TheApplicationConfigDialog.vue')
const ResetApplicationDialog = () => import('@/modules/application/components/ResetApplicationDialog')
const AppScheduleDialog = () => import('./AppScheduleDialog.vue')
const AppDeleteDialog = () => import('./AppDeleteDialog.vue')
const AppRenameDialog = () => import('./AppRenameDialog.vue')
const AppCloneBtn = () => import('./AppCloneBtn.vue')
const VersionDescriptionViewer = () => import('../components/VersionDescriptionViewer.vue')
const AppScaleInfoMenu = () => import('@/components/AppScaleInfoMenu.vue')

export default {
    name: 'SnapshotApplications',
    mixins: [appTypeAndImageLink, enumsData, tour, scaling],
    components: {
        ApplicationConfig,
        CopyToClipboard,
        ResetApplicationDialog,
        StopApplication,
        AppScheduleDialog,
        AppRenameDialog,
        VersionDescriptionViewer,
        AppCloneBtn,
        AppDeleteDialog,
        AppScaleInfoMenu
    },
    data() {
        return {
            search: '',
            schedules: [],
            headers: [
                { text: 'Type', align: 'left', value: 'icon', sortable: false, width: '30px' },
                { text: 'Name', align: 'left', value: 'long_id', class: 'pl-8' },
                { text: 'Size', align: 'left', value: 'size', class: 'pl-8' },
                { text: 'Credit/hour', align: 'left', value: 'active_resource' },
                { text: 'Status', value: 'status', align: 'left' },
                { text: 'Description', align: 'left', value: 'description' },
                { text: 'Space usage', align: 'left', value: 'lib_usage' },
                { text: 'Actions', align: 'right', value: 'actions' }
            ],
            scheduleHeaders: [
                { text: 'Type', align: 'left', value: 'icon', width: '30px' },
                { text: 'Name', align: 'left', value: 'long_id' },
                { text: 'Date', align: 'left', value: 'startup_time' },
                { text: 'Custom resources', align: 'left', value: 'type_value' },
                { text: 'Session length [min]', align: 'left', value: 'stop_after_minutes' },
                { text: 'Actions', align: 'right', value: 'actions' }
            ],
            itemsPerPageOptions: [25, 50, 100, -1],
            tableOptions: {
                page: 0,
                itemsPerPage: 25,
                pageStart: 1,
                pageStop: 25,
                itemsLength: 25
            },
            error: false,
            errorContent: '',
            selected: [],
            AddAppdialog: false,
            startingAppForAll: false,
            showOldSchedules: false,
            loading: false,
            dropdownMenu: {},
            tourId: 'seen_prestart_tour',
            tourHidden: false,
            exportingApp: null,
            dockerExportTag: '',
            skipFileExport: false,
            dockerExportTagDialog: false,
            dockerExportTagIsValid: true,
            rules: {
                validChars: p => /^[a-z0-9_-]*$/g.test(p) || 'Please use only lower case letters, numbers and dashes.'
            },
            envs: {},
            stagingTour: null,
            precision: process.env.VUE_APP_DASHBOARD_PRECISION
        }
    },
    created() {
        this.fetchSchedules()
        this.$store.dispatch('appStore/fetchNodePools')
        this.$store.dispatch('snapshotStore/fetchApplications', this.$route.params.snid)
        if (this.$route.query.search) this.search = this.$route.query.search
    },
    computed: {
        applicationsWithStatus: function () {
            return this.applications.map(a => {
                if (a.enabled_flag === 0 && a.delete_timestamp === null) {
                    a.status = 'Deleting'
                } else {
                    a.status = 'Stopped'
                }
                const appDeployment = this.deploymentStatus.filter(d => {
                    return d.aid.toString() === a.aid.toString()
                })
                if (appDeployment.length) {
                    if (appDeployment[0].replicas === appDeployment[0].available_replicas) {
                        a.status = 'Running'
                    } else if (appDeployment[0].replicas > appDeployment[0].available_replicas) {
                        a.status = 'Starting'
                    } else {
                        a.status = 'Stopping'
                    }
                    a.shared = appDeployment[0].shared
                }
                return a
            })
        },
        currentURL() {
            return window.location.href
        },
        headersFiltered: function () {
            const snapshotHeaders = ['Type', 'Name', 'Description', 'Actions']
            return this.isDevelopment
                ? this.headers
                : this.headers.filter(c => {
                      return snapshotHeaders.indexOf(c.text) >= 0
                  })
        },
        filteredSchedules() {
            if (this.showOldSchedules) {
                return this.schedules
            } else {
                return this.schedules.filter(schedule => !schedule.past)
            }
        },
        showPrestartTour() {
            return (
                !this.applicationsFetching &&
                !this.fetchingUserInfo &&
                !this.userMetadata.seen_prestart_tour &&
                this.privateInstances.length > this.tours.PRESTART_MINIMUM_INSTANCE_COUNT &&
                this.schedules.length === 0 &&
                !this.currentSpaceArchived &&
                this.isSpaceAdmin &&
                this.isMasterInstance &&
                this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE
            )
        },
        isRunning() {
            if (!this.exportingApp?.aid) return false
            return this.runningDeployments.concat(this.startingDeployments).find(deployment => {
                console.log(deployment.aid, this.exportingApp.aid)
                return deployment.aid.toString() === this.exportingApp.aid.toString()
            })
        },
        ...mapState(['userInfo']),
        ...mapState('tourStore', ['stageAppTourStarted']),
        ...mapGetters('appStore', ['runningDeployments', 'startingDeployments']),
        ...mapState('appStore', ['deploymentStatus']),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        ...mapState('snapshotStore', ['stagingObjects', 'applications', 'applicationsFetching']),
        ...mapState(['userMetadata', 'fetchingUserInfo']),
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'currentSpaceType', 'isSpaceArchived', 'currentSpaceArchived', 'privateInstances', 'isTrialSpace']),
        ...mapGetters('instanceStore', ['isInstanceEditor', 'isMasterInstance', 'isInstanceArchived', 'isDistributedInstance']),
        getDeleteForOthersLabel() {
            return this.isDistributedInstance && this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE ? 'Delete for students' : 'Delete for others'
        },
        getDeleteLabel() {
            if (this.currentSpaceType === this.spaceTypes.RESEARCH_SPACE) {
                return 'Delete'
            } else {
                if (this.isSpaceAdmin && this.isMasterInstance) {
                    return 'Delete for me'
                } else {
                    return 'Delete'
                }
            }
        }
    },
    methods: {
        async exportToImage() {
            this.dockerExportTagDialog = false
            const body = {}
            if (this.dockerExportTag) body.image_name = this.dockerExportTag
            if (this.skipFileExport) body.export_files = false
            try {
                await this.$axios.post(`/applications/${this.exportingApp.aid}/export`, body)

                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Successfully started exporting ${this.exportingApp.long_id}. You will be notified in email when it is available to use.`,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'check_circle'
                })
                this.$store.dispatch('userStore/fetchRunningUserTasks')
            } catch (error) {
                console.error(error)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `There was an error while starting export. Please try again later.`,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
        },
        removeSearchQuery() {
            this.$router.replace({
                ...this.$router.currentRoute,
                query: {
                    search: undefined
                }
            })
        },
        addToStaging: function (data) {
            if (data.length) {
                const stagingObjectsBefore = this.stagingObjects.applications.length
                const filesWithPaths = []
                let fileOrder = data.length
                data.forEach(file => {
                    const fileObject = file
                    fileObject.filePath = file.long_id
                    fileObject.fileType = this.nuvolosObjectTypes.APPLICATION
                    filesWithPaths.push(fileObject)
                    fileOrder = fileOrder - 1
                    if (fileOrder === 0) {
                        this.$store.dispatch('snapshotStore/updateStagingObjects', {
                            itemType: this.nuvolosObjectTypes.APPLICATION,
                            newItems: filesWithPaths,
                            updateMode: 'add'
                        })
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Applications added to stage.',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'check_circle'
                        })
                        this.selected = []
                    }
                    const stagingObjectsAfter = this.stagingObjects.applications.length
                    if (stagingObjectsAfter !== stagingObjectsBefore) {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Applications added to stage.',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'check_circle'
                        })
                    } else {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Selected applications already staged.',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'info'
                        })
                    }
                })
            }
        },
        startAppForAll(aoid) {
            this.startingAppForAll = true
            const postBody = {
                aoids: [aoid],
                explicit_editors_only: false,
                wait_secs: 6,
                max_users: 150
            }
            this.$axios
                .post(`/spaces/${this.$route.params.sid}/start_apps`, postBody)
                .then(response => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'User applications have been started!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to start the applications',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.startingAppForAll = false
                })
        },
        errorMessage: function (error) {
            if (error) {
                this.error = true
                this.errorContent = 'An error has occurred'
            }
        },
        openApp: function (aid) {
            this.$router.push({
                name: 'app-open',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid,
                    aid
                }
            })
        },
        humanFileSize: function (bytes, si) {
            return humanFileSize(bytes, si)
        },
        async fetchSchedules() {
            this.loading = true
            try {
                const { data } = await fetchAppStartupSchedules({ sid: this.$route.params.sid, all: true })
                const now = DateTime.now()
                data.forEach(schedule => {
                    const dt = DateTime.fromISO(schedule.startup_time)
                    if (dt < now) {
                        schedule.past = true
                    }
                    schedule.dateForInput = dt.toFormat(process.env.VUE_APP_DATETIMEFORMAT)
                })
                this.schedules = data
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        async setScheduleNextWeek(aoid, dateTime, nodePool, stopAfterMinutes) {
            this.loading = true
            const dt = DateTime.fromISO(dateTime)
            const nextWeek = dt.plus({ days: 7 })
            try {
                await setAppStartupSchedule({
                    sid: this.$route.params.sid,
                    aoid,
                    startupTime: dateTimeConcat(nextWeek.toISODate(), nextWeek.toFormat('T')),
                    nodePool: nodePool || null,
                    stopAfterMinutes
                })
                this.loading = false
                this.fetchSchedules()
            } catch (error) {
                this.loading = false
                console.log(error)
            }
        },
        async deleteSchedule(ssid) {
            this.loading = true
            try {
                await deleteAppStartupSchedule(ssid)
                this.loading = false
                this.fetchSchedules()
            } catch (error) {
                this.loading = false
                console.log(error)
            }
        },
        disableScheduleNextWeek(schedule) {
            const dt = DateTime.fromISO(schedule.startup_time)
            const now = DateTime.now()
            return dt.plus({ days: 7 }) < now
        },
        closeMenu(app) {
            this.dropdownMenu[`app_${app.aid}`] = false
        },
        showDockerTagDialogForApp(app) {
            this.exportingApp = app
            this.dockerExportTagDialog = true
        }
    },
    watch: {
        applicationsWithStatus: function (nextValue, preValue) {
            if (nextValue.length < preValue.length) {
                if (this.selected.length === 1) {
                    this.selected = []
                }
            }
        },
        applicationsFetching(newVal, oldVal) {
            // started fetching
            if (this.stageAppTourStarted && this.stagingTour && newVal && !oldVal) {
                this.$nextTick(() => {
                    this.stagingTour.cancel()
                })
            }

            // finished fetching
            if (this.stageAppTourStarted && this.stagingTour && !newVal && oldVal) {
                this.$nextTick(() => {
                    this.stagingTour.start()
                })
            }
        },
        stageAppTourStarted: {
            handler(val) {
                if (val) {
                    this.stagingTour = new Shepherd.Tour({
                        useModalOverlay: true,
                        defaultStepOptions: {
                            scrollTo: { behavior: 'smooth' }
                        }
                    })
                    this.stagingTour.addStep({
                        title: 'Staging applications',
                        text: `Hover on the selected object and in the Actions columns select "Stage" to add it to the staging area for the next distribution.`,
                        attachTo: {
                            element: '.shepherd-app-staging-step-1',
                            on: 'top'
                        },
                        id: 'staging-apps-1',
                        buttons: [
                            {
                                text: 'Ok',
                                action: this.stagingTour.complete
                            }
                        ]
                    })
                    this.stagingTour.start()
                    Shepherd.on('complete', () => {
                        this.stagingTour = null
                        this.$store.commit('tourStore/stageAppTourStarted', false)
                    })
                }
            },
            immediate: true
        },
        showPrestartTour: {
            handler(show) {
                if (!show) return
                this.$nextTick(() => {
                    this.tourHidden = true
                    this.tour = new Shepherd.Tour({
                        useModalOverlay: true,
                        defaultStepOptions: {
                            cancelIcon: {
                                enabled: true
                            },
                            scrollTo: { behavior: 'smooth', block: 'center' }
                        }
                    })
                    this.tour.addSteps([
                        {
                            title: 'Schedule automatic start',
                            text: `Your application has a lot of instances. You can save time by making sure they are all up and running by the time you need them.<br /><br />Each application can be scheduled to start using their settings menu.`,
                            buttons: [
                                {
                                    action() {
                                        this.hide()
                                        document.querySelector('.prestart_tour_1:first-of-type').click()
                                        setTimeout(() => {
                                            return this.next()
                                        }, 500)
                                    },
                                    text: 'Next'
                                }
                            ],
                            attachTo: {
                                element: '.prestart_tour_1:first-of-type',
                                on: 'left'
                            },
                            id: 'step-1'
                        },
                        {
                            title: 'Schedule automatic start',
                            text: `Select <em>Schedule for start</em> to display the scheduling form.`,
                            buttons: [
                                {
                                    action() {
                                        document.querySelector('.prestart_tour_2').click()
                                        this.hide()
                                        setTimeout(() => {
                                            document.querySelector('.prestart_tour_2').click()
                                        }, 250)
                                        setTimeout(() => {
                                            return this.next()
                                        }, 750)
                                    },
                                    text: 'Next'
                                }
                            ],
                            attachTo: {
                                element: '.prestart_tour_2',
                                on: 'left'
                            },
                            id: 'step-2'
                        },
                        {
                            title: 'Schedule automatic start',
                            text: `Depending on the number of instances, it may take a moment for the applications to start. We will make sure they are all started by the time you set here.<br/><br/>If you don't want to schedule a date now, just click cancel.`,
                            buttons: [
                                {
                                    action() {
                                        return this.complete()
                                    },
                                    text: 'Got it!'
                                }
                            ],
                            attachTo: {
                                element: '.v-dialog--active',
                                on: 'right'
                            },
                            id: 'step-3'
                        }
                    ])
                    this.tour.start()
                })
            }
        }
    }
}
</script>
